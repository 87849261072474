<template>
  <div id="trip-edit">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
          />行程修改
        </van-col>
        <van-col
          span="14"
          class="header-title"
          style="
            text-align: right;
           
          "
          @click="onDoEditClick"
        >
          完成
        </van-col>
      </van-row>
    </div>
    <div class="trip-item-body">
      <div class="item-body-title">行程信息</div>
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">行程名称</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="travel_plan.plan_name"
            placeholder="请输入行程名称"
          />
        </van-col>
      </van-row>
      <!-- <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">行程介绍</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="travel_plan.description"
            placeholder="请输入行程介绍"
          />
        </van-col>
      </van-row> -->
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">开始地点</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="travel_plan.start_addr"
            placeholder="请输入开始地点"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">结束地点</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="travel_plan.end_addr"
            placeholder="请输入结束地点"
          />
        </van-col>
      </van-row>
      <div class="xianlu-container">
        <!-- <div class="item-body-title">行程介绍</div>
        <van-row class="description-container">
        <van-col span="24">
          <textarea
            class="description-text-area"
            v-model="travel_plan.description"
            placeholder="请输入行程介绍"
            name=""
            id=""
            cols="20"
            rows="5"
          ></textarea>
        </van-col>
      </van-row> -->
        <div class="item-body-title">封面图</div>
        <van-uploader
          max-count="1"
          v-model="fileList"
          :before-delete="onDeleteImageUrl"
          
          preview-full-image
          accept="image/*"
          :after-read="fnUploadRequest"
        />
        <div class="item-body-title">行程线路</div>
        <div class="trip-step">
          <van-steps
            direction="vertical"
            active-color="#969799"
            active-icon="clock"
            :active="-1"
          >
            <van-step v-for="(item, index) in travel_plan.nodes" :key="index">
              <h3>
                <input
                  type="text"
                  :disabled="item._destroy"
                  v-model="item.node_name"
                  placeholder="请输入行程名称"
                />
              </h3>
              <p>
                <input
                  type="text"
                  :disabled="item._destroy"
                  v-model="item.node_description"
                  placeholder="请输入行程描述"
                />
              </p>
              <div>
                <van-icon
                  name="clear"
                  v-if="!item._destroy"
                  class="add_clear_icon"
                  @click="beforeClose(index, 1)"
                />
                <span
                  v-if="item._destroy"
                  @click="beforeClose(index, 2)"
                  style="color: #f04134; font-size: 10px; margin-right: 10px"
                  >取消删除</span
                >
                <van-icon
                  name="add"
                  class="add_clear_icon"
                  @click="onAddClick(index)"
                />
              </div>

              <!-- <div >
              <svg-icon :data_iconName="'add'" :className="'add_icon'" />
            </div> -->
            </van-step>
          </van-steps>
        </div>
      </div>
      <div class="btn"  @click="onDoEditClick">完成</div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import oss from "@/utils/oss";
export default {
  name: "trip-edit",
  components: {},
  data() {
    return {
      id: "",
      fileList: [],
      active: 1,
      show: false,
      item: {},
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        plan_name: "",
      },
      travel_plan: {
        plan_name: "",
        description: "",
        status: "1",
        image_url: "",
        travel_plan_nodes_attributes: [
          {
            sort_id: 1,
            node_name: "",
            node_description: "",
          },
        ],
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    beforeClose(index, type) {
      const _self = this;
      Dialog.confirm({
        title: "温馨提醒",
        message: type == 1 ? "确认删除吗？" : "确认取消删除吗？",
      })
        .then(() => {
          _self.travel_plan.nodes[index]._destroy =
            !_self.travel_plan.nodes[index]._destroy;
        })
        .catch(() => {});
    },
    onConfirm() {},
    onAddClick(index) {
      const attributes_length = this.travel_plan.nodes.length;
      const next_sort_id = this.travel_plan.nodes[index].sort_id + 1;

      this.travel_plan.nodes.splice(index + 1, 0, {
        sort_id: next_sort_id,
        node_name: "",
        node_description: "",
        _destroy: false,
      });

    },
    async onDoEditClick() {
      if (this.travel_plan.plan_name == "") {
        Toast("请填写行程名称");
        return false;
      }
      // if (this.travel_plan.description == "") {
      //   Toast("请填写行程介绍");
      //   return false;
      // }
      if (this.travel_plan.end_addr == "") {
        Toast("请填写行程结束地点");
        return false;
      }
      if (this.travel_plan.start_addr == "") {
        Toast("请填写行程开始地点");
        return false;
      }
      var flag = true;
       this.travel_plan.nodes.forEach((value) => {
        if ((value.node_name == "" || value.node_description == "")&&(!value._destroy)) {
          flag = false;
          return false;
        } else {
          return true;
        }
      });
     
      if (!flag) {
        Toast("请填写完整再提交");
        return false;
      }
      this.travel_plan.nodes.forEach((el, index) => {
        el.sort_id = index + 1;
      });
      const travel_plan = {
        plan_name: this.travel_plan.plan_name,
        description: this.travel_plan.description,
        end_addr: this.travel_plan.end_addr,
        start_addr: this.travel_plan.start_addr,
        status: "1",
        travel_plan_nodes_attributes: this.travel_plan.nodes,
        image_url:this.travel_plan.image_url
      };
      const res = await this.$apis.put_travel_plans(
        { travel_plan: travel_plan },
        this.travel_plan.id
      );
      if (res.status == 200) {
        Toast("修改成功");
        // setTimeout()
        this.$router.go(-1);
      } else {
        Toast("修改失败");
      }
    },
    onItemClick() {
      this.$router.push("/trip/item");
    },
    onEditClick() {
      this.$router.push({ path: "/trip/edit" });
    },
    onShowClick() {
      //   this.item = item;
      this.show = true;
    },
    onDeleteImageUrl() {
      this.travel_plan.image_url = "";
      this.fileList = [];
    },
    async get_travel_plan(id) {
      const res = await this.$apis.get_travel_plan(id);
      this.travel_plan = res.data;
      if(res.data.image_url){
        this.fileList = [
          {
            url: res.data.image_url?res.data.image_url:'',
          },
        ];
      }
       
    },
    fnUploadRequest(option) {
      const _self = this;
      // Toast.loading({
      //   message: "上传中...",
      //   forbidClick: true,
      // });
      option.status = "uploading";
      option.message = "上传中...";
      oss.ossUploadFile(option).then((res) => {
        _self.travel_plan.image_url = res.fileUrl[0].split("?")[0];
        option.status = "success";
        // option.message = '上传中...';
        _self.fileList = [
          {
            url: res.fileUrl[0].split("?")[0],
          },
        ];
        Toast.clear();
      });
    },
  },
  created() {
    this.id = this.$route.query.id;

    this.get_travel_plan(this.id);
  },
};
</script>

<style scoped>
#trip-edit {
  background: #f3f3f3;
  height: 100vh;
  overflow-y: hidden;
  padding-top:10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.trip-item-body {
  height: 85vh;
  width: 90%;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
  position: relative;
  background-color: #fff;
}
.item-body-title {
  font-size: 0.3rem;
  color: #333333;
  font-weight: bold;
  margin-bottom: 20px;
}
.input-container {
  background: #eaeaea;
  border-radius: 24px;
  border-radius: 24px;
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.4rem;
  margin-top: 0.3rem;
}
.input-text {
  text-align: left;
  font-size: 12px;
  color: #333333;
}
.input-item > input {
  background: #eaeaea;
  width: 70%;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #333;
  font-size: 12px;
  float: right;
  text-align: left;
}
.input-item > input::placeholder {
  font-size: 12px;
  color: #b1b1b1;
  text-align: left;
}
.xianlu-container {
  margin-top: 0.4rem;
}
.trip-step {
  /* margin-top: 0.4rem;border-top:1px dashed #ebedf0;
    padding-top: .5rem; */
}
.add_icon {
  font-size: 0.5rem;
}
.trip-step input::placeholder {
  color: #999;
}
input:disabled {
  background: #fff !important;
}
.add_clear_icon {
  font-size: 0.3rem !important;
  margin-right: 5px !important;
}
.description-container {
  margin-top: 20px;
}
.description-text-area {
  border-radius: 0.2rem;
  background: #eaeaea;
  width: 100%;
  padding: 0.3rem;
  font-size: 12px;
}
.btn{
  height: .8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: .8rem;
  margin-top:.4rem;
  font-size: .3rem;
  border-radius: 5px;
}
</style>
